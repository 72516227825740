@import './../variables.module.scss';

.rightWrapper {
  /*flex-grow: 1;*/
  width: $right;
  background-color: #fff;

  .rightTop, .rightBottom {
    background-color: #fff;
  }
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    border: 1px solid rgba(108, 117, 125, 0.25);
    color: #55585A;
    position: relative;
    user-select: none;
  }
  .content {
    height: calc(100% - 34px);
    overflow-y: auto;
    border-right: 1px solid rgba(108, 117, 125, 0.25);;
    border-left: 1px solid rgba(108, 117, 125, 0.25);
    .item {
      font-size: 14px;
      color: #6C757D;
      padding: 5px 5px 5px 65px;
      border: 1px solid #ddd;
      min-height: 60px;
      position: relative;
      p {
        margin: 0;
        line-height: 26px;
      }
      span {
        font-size: 12px;
        // letter-spacing: -0.5px;
      }
      .value {
        font-size: 14px;
        font-weight: 700;
      }
      input {
        font-size: 14px;
        padding: 1px 4px;
        font-weight: 600;
        color: rgba(108, 117, 125, 1);
      }
    }
  }

  .rightTop {
    .content {
      .item {
        padding: 4px;
        cursor: pointer;
        position: relative;
        // margin-top: -2px;
        // &:first-child {
        //   margin-top: 0;
        // }
        &.trained {
          background-color: rgba(15, 37, 64, 0.05);
        }
        
        &.targetPredict {
          // border: solid 2px cyan;
        }
        &.targetManual {
          // border: solid 2px fuchsia;
        }
        &.targetStorage {
          // background-color: blue;
        }
        // &.active {
        //   // background-color: red;
        //   &::after, &::before {
        //     content: '';
        //     width: 6px;
        //     display: block;
        //     border-top: solid 6px red;
        //     border-bottom: solid 6px red;
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     bottom: 0;
        //   }
        //   &::after {
        //     left: initial;
        //     right: 0;
        //   }
        // }
      }

      &.mode_BEHAVIOR_MODE_EDIT {
        .item.active {
          background-color: rgba(218, 160, 1, 0.25);
          border: solid 1px rgba(218, 160, 1, 1);
          input {
            color: rgba(218, 160, 1, 1);
          }
        }
      }
      &.mode_BEHAVIOR_MODE_VIEW {
        .item.active {
          background-color: rgba(132, 209, 72, 0.3);
          border: solid 1px rgba(132, 209, 72, 1);
          input {
            color: rgba(132, 209, 72, 1);
          }
        }
      }
    }
  }
  .rightBottom {
    .content {
      .item {
        padding: 4px 5px 4px 77px;

        .checker {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 72px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: black;
          cursor: pointer;
          .btn {
            cursor: pointer;
            position: absolute;
            right: 4px;
            bottom: 4px;
            font-weight: 600;
            color: white;
            border-radius: 2px;
            padding: 2px 2px;
            font-size: 14px;
            line-height: 14px;
            
            transition: background-color 0.1s ease;
            &:hover {
              background-color: rgba(255, 255, 255, 0.3);
            }
          }
        }
      }
    }
  }

  .closeButton {
    position: absolute;
    right: 7px;
    bottom: 6px;
    cursor: pointer;
    svg {
      path {
        fill: black;
        fill-opacity: 0.3;
      }
    }
    &:hover {
      svg {
        path {
          fill: black;
          fill-opacity: 0.6;
          transition: fill-opacity 0.3s ease;
        }
      }
    }
  }

  .topIcon {
    // width: 24px;
    height: 32px;
    margin-right: 4px;
    display: inline-flex;
    align-items: center;
    svg {
      path {
        fill: rgb(255, 0, 0);
        fill-opacity: 0.5;
      }
    }
  }

  .botIcon {
    // width: 24px;
    height: 32px;
    margin-right: 4px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    svg {
      border-radius: 4px;
      path {
        fill: black;
        fill-opacity: 0.5;
      }
    }
    &:hover {
      svg {
        background-color: rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
        path {
          fill: black;
          fill-opacity: 0.6;
          transition: fill-opacity 0.3s ease;
        }
      }
    }
  }
}