@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@200;300;400;500;600;700;800;900&family=Noto+Sans+TC:wght@300;400;500;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700;900&display=swap');

$primary: #6bc7f1;
$secondary: #091524;
$hightlight: #6e2002;

$grid-gutter-width: .5rem;

@import "node_modules/bootstrap/scss/bootstrap";

:root {
  --bs-font-sans-serif: 'Noto Sans Mono', 'Noto Sans TC', sans-serif;
}

.App {
  .accordion-item, .accordion-collapse, .accordion-body, .list-group, .list-group-item {
    background-color: rgba(0, 0, 0, 0);
    .svg-inline--fa {
      margin: 0 .5rem 0 0;
      width: 22px;
      height: 22px;
      flex-shrink: 0;
      path {
        fill: #0F2540;
        fill-opacity: 1.0;
      }
    }
  }

  .list-group-item {
    position: static;
    border: none;
    font-size: 0.9rem;
    display: flex;
    align-items: center;

    padding: .15rem 1rem;
    &.intend-1 {
      padding-left: 2rem;
    }
    &.intend-2 {
      padding-left: 3rem;
    }

    span.text {
      display: inline-block;
      vertical-align: top;
      line-height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    cursor: pointer;
    transition: background-color .3s ease;
    &.showed {
      background-color: rgba(107, 199, 241, 0.2);
      border-color: rgba(107, 199, 241, 0.2);
    }
    &.active {
      cursor: default;
      color: #212529;
      background-color: #6bc7f1;
      border-color: #6bc7f1;
    }
    &.cached {
      color: $hightlight;
      svg {
        path {
          &:first-child(), &:nth-child(2) {
            fill: $hightlight;
          }
        }
      }
    }
    &:hover:not(.active) {
      background-color: rgba(107, 199, 241, 0.8);
      border-color: rgba(107, 199, 241, 0.8);
    }
  }

  .accordion-button {
    font-size: 0.9rem;
    transition: background-color .3s ease;
    position: relative;
    & > * {
      position: relative;
      z-index: 1;
    }  
    &:hover {
      background-color: rgba(0, 0, 0, .05);
    }
    span.text {
      display: inline-block;
      vertical-align: top;
      line-height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    
    &:focus {
      border: none;
      box-shadow: none;
    }
    &, &:not(.collapsed) {
      border: 1px rgba(0, 0, 0, 0);
      background-color: rgba(0, 0, 0, 0);
      color: inherit;
      box-shadow: none;
    }
    &:not(.collapsed) {
      border: 1px rgba(0,0,0,.08);
      background-color: rgba(0,0,0,.1);
    }
    &::after {
      display: none;
    }

    .fa-folder-open {
      display: none;
    }
    .fa-folder {
      display: inline-block;
    }
    &:not(.collapsed) {
      .fa-folder-open {
        display: inline-block;
      }
      .fa-folder {
        display: none;
      }
    }
  }
  .accordion-button {
    padding: .3rem 1rem;
  }
  .intend-1 {
    .accordion-button {
      padding-left: 2rem;
    }
  }
  .intend-2 {
    .accordion-button {
      padding-left: 3rem;
    }
  }
  .highlight {
    .accordion-button {
      
      &::before {
        content: '';
        position: absolute;
        left: 2.4rem;
        right: 0.75rem;
        top: 0.25rem;
        bottom: 0.25rem;
        background-color: rgb(226, 245, 213);
        border-radius: 4px;
      }
    }
  }

  .navbar-expand.navbar {
    padding: 0.5rem;
    z-index: 9;
    .navbar-nav {
      align-items: center;
    }
    .navbar-brand {
      font-weight: 700;
    }
    .nav-item {
      .nav-link {
        color: #fff;
        font-weight: 600;
        padding: 8px 16px;
        line-height: 24px;
      }

      &.show {
        .nav-link {
          background: rgba(255, 255, 255, 0.75);
          color: #091524;
        }
      }
    }
  }

  .nav-item.dropdown {
    margin: 0;
    display: flex;
    align-items: center;
    &.show {

    }
    .dropdown-toggle::after {
      display: none;
    }
  }
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.dropdown-item {
  align-items: center;
  display: flex;
  svg {
    margin-right: 8px;
  }

  &.disabled {
    svg {
      path {
        fill: #adb5bd
      }
    }
  }
}

.modal-header {
  padding: 0.5rem 1rem;
}
.modal-footer {
  padding: 0.5rem 0.75rem;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  opacity: 0.4;
}

.alert {
  padding: 0.25rem 0.5rem;
  font-size: 14px;
}

.modal-content {
  .list-group-item {
    font-size: 14px;
    padding: 0.25rem 0.5rem;
  }
}

.modal-dialog.about {
//   background-color: rgba(0, 0, 0, 0);
  max-width: 780px;
}
.modal-backdrop.about {
  background-color: rgba(0, 0, 0, 0);
}
.modal-content.about {
  background-color: #fff;
  padding: 32px;
  border-radius: 0;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3);
  border: none;

  .tabs {
    display: flex;
    .tab {
      padding: 0 36px;
      line-height: 34px;
      cursor: pointer;
      background-color: rgba(234, 235, 236, 0.5);
      color: rgba(15, 37, 64, 0.75);
      border-top: 1px solid rgba(108, 117, 125, 0.25);
      border-right: 1px solid rgba(108, 117, 125, 0.25);
      // border-bottom: 1px solid rgba(0, 0, 0, 0);
      // border-left: 1px solid rgba(0, 0, 0, 0);
      &:first-child {
        border-left: 1px solid rgba(108, 117, 125, 0.25);
      }
      &.active {
        background-color: #fff;
        position: relative;
        z-index: 1;
        color: rgba(15, 37, 64, 1);
      }
    }
  }
  .tab-content {
    background-color: #fff;
    padding: 32px;
    border: 1px solid rgba(108, 117, 125, 0.25);
    margin-top: -1px;
    max-height: 500px;
    overflow-y: auto;
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.44px;
      text-align: justify;
      margin: 0 0 24px;
      // color: rgba(0, 0, 0, 0.6);
    }
    .image-wrapper {
      text-align: right;
      margin-top: 35px;
      margin-bottom: -12px;
      img {
        display: inline-block;
        vertical-align: top;
        width: 360px;
        height: auto;
      }
    }
    .label {
      color: #fff;
      background-color: #999;
      display: inline-block;
      vertical-align: top;
      padding: 0 4px;
      line-height: 22px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.44px;
      text-align: center;
    }
  }
  
}

.z-index-higher {
  z-index: 1100;
}

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}