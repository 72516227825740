.App {
  /* text-align: center; */
}

body {
  overflow-y: hidden;
  color: #333;
  font-family: var(--bs-body-font-family) !important;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

::-webkit-scrollbar {
  background-color: #f9f9f9;
  width: 16px;
}
::-webkit-scrollbar-track {
  background-color: #f9f9f9;
}
::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 16px;
  border: 4px solid #f9f9f9;
}
::-webkit-scrollbar-button {
  display: none;
}

.hide-scrollbar {
  /*FireFox*/
  scrollbar-width: none;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.hide-scrollbar::-webkit-scrollbar {
  /*Chrome, Safari, Edge*/
  display: none;
}



.firebaseui-title {
  text-align: center !important;
}

.mdl-button--raised.mdl-button--colored {
  width: 100%;
  margin: 0;
  background: rgba(15, 37, 64, 1) !important;
  height: 48px;
}

.firebaseui-form-links {
  display: none !important;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label:after {
  background-color: #0F2540 !important;
}