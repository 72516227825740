$hightlight: #6e2002;

.body {
  z-index: 99;
  position: relative;
  .resizer {
    position: absolute;
    opacity: 0;
    background-color: rgba(108, 117, 125, 0.75);
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 1;
    }
  }

  &.vertical {
    width: 0;
    height: 100%;
    .resizer {
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      width: 4px;
      height: 100%;
      cursor: col-resize;
    }
  }
  &.horizontal {
    width: 100%;
    height: 0;
    .resizer {
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      height: 4px;
      cursor: row-resize;
    }
  }
}