@import './../variables.module.scss';

.centerWrapper {
  /*flex-grow: 1;*/
  width: calc(calc(100% - #{$left}) - #{$right});
  position: relative;
  padding-top: 34px;
  padding-bottom: $bottom;
  
  .tabs {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 34px;
    overflow-x: auto;
    white-space: nowrap;
    // padding-right: 50%;
    border-bottom: 1px solid rgba(108,117,125,.25);
    .tab {
      &:last-child {
        margin-right: 100px;
      }
      display: inline-flex;
      vertical-align: top;
      line-height: 34px;
      white-space: nowrap;
      padding: 0 8px 0 10px;
      border-right: 1px solid rgba(108, 117, 125, 0.25);
      // position: relative;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      color: rgba(51, 51, 51, 0.75);
      background-color: rgba(234, 235, 236, 0.5);
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #fff;
      }
      &.activeTab {
        background-color: #fff;
        pointer-events: none;
        color: #333333;
      }
    }
  }

  .controls {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    .item {
      margin: 1px 0;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid rgba(108,117,125,.25);
      background-color: #fff;
      cursor: pointer;
      position: relative;
      transition: 0.3s ease;
      &:hover {
        background-color: #efefef;
      }
      &.active {
        background-color: #E5E5E5;
      }
      &.disabled {
        background-color: #fff;
        path {
          fill: #bbb;
        }
        pointer-events: none;
      }
      
      .photos {
        position: absolute;
        top: calc(100% + 4px);
        right: 4px;
        z-index: 1;
        background-color: #fff;
        border-radius: 4px;
        padding: 8px 0;
        max-height: 600px;
        overflow-y: auto;
        font-size: 12px;
        font-weight: 600;
        color: #333333;
        .photo {
          // font-size: 13px;
          padding: 0 8px;
          line-height: 30px;
          transition: 0.3s ease;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          &:hover {
            background-color: #E5E5E5;
          }
          &.active {
            background-color: #6bc7f1;
            pointer-events: none;
            // color: #fff;
          }
        }
      }
    }
  }

  .closeBtn {
    margin-left: 8px;
    cursor: pointer;
    pointer-events: all;
    svg {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      path {
        fill: black;
        fill-opacity: 0.6;
      }
    }
    &:hover {
      svg {
        background-color: rgba(0, 0, 0, 0.2);
        transition: background-color 0.3s ease;
      }
    }
  }

  .innerWrapper {
    height: 100%;
    // overflow-y: auto;
    // overflow-x: hidden;
    overflow: hidden;
    position: relative;
  }

  .statusWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #505050;
    background: #fff;
    border-top: 1px solid rgba(108, 117, 125, 0.25);
    height: $bottom;
    overflow-y: auto;
    .logs {
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      min-height: 100%;
      p {
        font-size: 12px;
        line-height: 18px;
        color: #41464B;
        margin: 0;
      }
    }
    
  }
}

.canvasWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  // height: 100%;
  user-select: none;
  img {
    display: block;
    width: 100%;
  }
  canvas {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.predictPhoto {
  position: absolute;
  left: 0;
  top: 34px;
  width: 240px;
  height: auto;
  pointer-events: none;
  border: 3px solid #FFFFFF;
}

.infobox {
  position: absolute;
  left: 0;
  bottom: $bottom;
  pointer-events: none;
  padding: 0px 4px;
  background-color: rgba(0, 0, 0, 0.2);
  p {
    margin: 0;
  }
  span {
    font-size: 14px;
    font-weight: 700;
    color: white;
  }
}