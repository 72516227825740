@import './variables.module.scss';

.wrapper {
  display: flex;
  height: 100%;
}

.left {
  width: calc(#{$left} - 12px);
}
.center {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
.right {
  width: calc(#{$right} - 12px);
  display: flex;
  justify-content: flex-end;
}

.sizeWarningCover {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f6f7;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '請調整視窗大小，或使用更高的螢幕解析度，來顯示軟體操作介面';
  }
  display: none;
  @media screen and (max-width: 1024px) {
    display: flex;
  }
}

.quickItems {
  margin: 0;
  .item {
    padding: 6px 15px;
    cursor: pointer;
    svg {
      path {
        fill: white;
        fill-opacity: 0.7;
      }
    }
    &:hover {
      svg {
        path {
          transition: fill-opacity 0.3s ease;
          fill: white;
          fill-opacity: 1;
        }
      }
    }
  }
}