@import './../variables.module.scss';

.leftWrapper {
  /*flex-grow: 1;*/
  width: $left;
  background-color: #fff;
  border-right: 1px solid rgba(108, 117, 125, 0.25);
  .contentWrapper {
    height: calc(100% - 34px);
    overflow-y: auto;
    width: 100%;
    padding: .5rem 0;
  }
  .tabWrapper {
    height: 34px;
    display: flex;
    .tab {
      /*flex-grow: 1;*/
      width: 50%;
      text-align: center;
      line-height: 34px;
      font-weight: 600;
      font-size: 14px;
      color: rgba(85, 88, 90, 0.75);
      background-color: rgba(234, 235, 236, 0.5);
      cursor: pointer;
      border-bottom: 1px solid rgba(108, 117, 125, 0.25);
      border-right: 1px solid rgba(108, 117, 125, 0.25);
      user-select: none;
      &:last-child {
        border-right: 1px rgba(0, 0, 0, 0) !important;
      }
    }
    .tabActive {
      color: #55585A;
      background-color: #fff;
      pointer-events: none;
      border-bottom: 1px rgba(0, 0, 0, 0);
    }
  }
}

.expandedText {
  position: fixed;
  display: none;
  pointer-events: none;
  line-height: 20px;
  border: 1px solid #eaebec;
  border-radius: 2px;
  font-size: 0.9rem;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.7);
}
.contentWrapper {
  .expandedText {
    opacity: 0;
    transition: opacity 0.3s ease; 
  }
  &:hover {
    .expandedText {
      opacity: 1;
    }
  }
}